import {useCallback, useMemo, useState} from "react";
import * as React from "react";
import Container from "../Layout/Container";
import Media from "../Common/Media";
import Button from "../UI/Button";
import Link from "../UI/Link";
import * as listStyles from "./testimonials-list.module.css";

const TestimonialsList = ({logos, testimonials, activeProductSlug: initialProductSlug = "all"}) => {
    const testimonialsPerPage = 7;
    const totals = testimonials.reduce(
        (acc, {node: testimonial}) => {
            if (testimonial.products.length === 0) {
                acc.all++;
                return acc;
            }

            testimonial.products.forEach(({slug}) => {
                if (!acc[slug]) {
                    acc[slug] = 0;
                }

                acc[slug]++;
            });

            acc.all++;

            return acc;
        },
        {all: 0},
    );

    const logosMap = logos.edges.reduce((acc, {node: {logo}}) => {
        if (!logo?.id || acc[logo.id]) {
            return acc;
        }

        return {...acc, [logo.id]: logo};
    }, {});

    const [activeProductSlug, setActiveProductSlug] = useState(initialProductSlug);
    const [page, setPage] = useState(1);

    const activeTestimonials = useMemo(() => {
        return testimonials.filter(({node: {products}}) => {
            return activeProductSlug === "all" || products.some(({slug}) => slug === activeProductSlug);
        });
    }, [testimonials, activeProductSlug]);

    const visibleTestimonials = useMemo(() => {
        return activeTestimonials.slice(0, testimonialsPerPage * page);
    }, [activeTestimonials, page]);

    const changeProductType = useCallback((newType) => {
        setActiveProductSlug(newType);
        setPage(1);
    }, []);

    const loadMore = useCallback(() => setPage((currentPage) => currentPage + 1), []);

    const mapTestimonials = ({node: testimonial}) => {
        const logoData = logosMap[testimonial.logo?.id];

        return (
            <blockquote key={testimonial.strapiId} className={listStyles.testimonialsList__item}>
                <div>
                    <p className={listStyles.testimonialsList__itemContent}>{testimonial.testimonial}</p>
                    {testimonial.case?.slug && (
                        <Link className={listStyles.testimonialsList__itemLink} to={`/blog/${testimonial.case?.slug}`}>
                            Посмотреть кейс
                        </Link>
                    )}
                </div>
                <footer>
                    <cite className={listStyles.testimonialsList__itemTitle}>{testimonial.author}</cite>
                    {logoData && (
                        <Media
                            media={{content: logoData, youtubeVideoURL: ""}}
                            className={listStyles.testimonialsList__itemLogo}
                        />
                    )}
                </footer>
                {testimonial.case?.slug && (
                    <Link
                        className={`${listStyles.testimonialsList__itemLink} ${listStyles.testimonialsList__itemLink_mobile}`}
                        to={`/blog/${testimonial.case?.slug}`}
                    >
                        Посмотреть кейс
                    </Link>
                )}
            </blockquote>
        );
    };

    return (
        <Container className={listStyles.testimonialsList}>
            <div className={listStyles.testimonialsList__buttonContainer}>
                <Button
                    variant="gradient"
                    type="button"
                    onClick={() => changeProductType("all")}
                    isActive={activeProductSlug === "all"}
                >
                    Все
                </Button>
                {Object.entries(totals).map(([slug, count]) => {
                    if (count <= 0 || slug === "all") {
                        return null;
                    }

                    return (
                        <Button
                            variant="gradient"
                            type="button"
                            key={slug}
                            onClick={() => changeProductType(slug)}
                            isActive={activeProductSlug === slug}
                        >
                            {`${slug[0].toUpperCase()}${slug.slice(1)}`}
                        </Button>
                    );
                })}
            </div>
            <div>{visibleTestimonials.map(mapTestimonials)}</div>
            {totals[activeProductSlug] > visibleTestimonials.length && (
                <div className={listStyles.testimonialsList__buttonContainer}>
                    <Button
                        variant="primary"
                        type="button"
                        onClick={loadMore}
                        className={listStyles.testimonialsList__button}
                    >
                        Показать больше отзывов
                    </Button>
                </div>
            )}
        </Container>
    );
};

export default TestimonialsList;
