import {StaticImage} from "gatsby-plugin-image";
import PropTypes from "prop-types";
import * as React from "react";
import Section from "../Layout/Section";
import Media from "../Common/Media";
import * as heroStyles from "./testimonials-hero.module.css";

const TestimonialsHero = ({counters, clients}) => {
    return (
        <Section>
            <div className={heroStyles.testimonialsHero}>
                <h1 className={heroStyles.testimonialsHero__title}>
                    Десятки компаний разного уровня выбрали <span>ITSM 365</span>
                </h1>
                <div className={heroStyles.testimonialsHero__clients}>
                    {clients.map((clientData, idx) => {
                        const {image} = clientData;

                        return (
                            image && (
                                <Media
                                    key={idx}
                                    media={{content: image, youtubeVideoURL: ""}}
                                    className={heroStyles.testimonialsHero__clientsLogoWrapper}
                                    imageProps={{
                                        loading: "lazy",
                                        imgClassName: heroStyles.testimonialsHero__clientsLogo,
                                    }}
                                />
                            )
                        );
                    })}
                </div>
                <div className={heroStyles.testimonialsHero__counts}>
                    {counters.map(({id, number, description}) => {
                        return (
                            <div key={id}>
                                <h2 className={heroStyles.testimonialsHero__countsTitle}>{number}</h2>
                                <p className={heroStyles.testimonialsHero__countsDesc}>{description}</p>
                            </div>
                        );
                    })}
                </div>
                <StaticImage
                    src="../../assets/images/testimonials/blur/desktop.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    className={`${heroStyles.testimonialsHero__gradient} ${heroStyles.testimonialsHero__gradient_desktop}`}
                />
                <StaticImage
                    src="../../assets/images/testimonials/blur/tablet.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    className={`${heroStyles.testimonialsHero__gradient} ${heroStyles.testimonialsHero__gradient_tablet}`}
                />
                <StaticImage
                    src="../../assets/images/testimonials/blur/mobile.png"
                    alt=""
                    layout="fixed"
                    placeholder="none"
                    className={`${heroStyles.testimonialsHero__gradient} ${heroStyles.testimonialsHero__gradient_mobile}`}
                />
            </div>
        </Section>
    );
};

TestimonialsHero.propTypes = {
    counters: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            number: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }),
    ).isRequired,
    clients: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.shape({
                mime: PropTypes.string.isRequired,
                width: PropTypes.number.isRequired,
                height: PropTypes.number.isRequired,
                localFile: PropTypes.shape({
                    publicURL: PropTypes.string,
                    childImageSharp: PropTypes.shape({
                        gatsbyImageData: PropTypes.shape({}),
                    }),
                }),
            }),
        }),
    ).isRequired,
};

export default TestimonialsHero;
