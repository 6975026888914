// extracted by mini-css-extract-plugin
export var testimonialsHero = "testimonials-hero-module--testimonials-hero--wtGjv";
export var testimonialsHero__title = "testimonials-hero-module--testimonials-hero__title--wq+wP";
export var testimonialsHero__clients = "testimonials-hero-module--testimonials-hero__clients--PkiD2";
export var testimonialsHero__clientsLogoWrapper = "testimonials-hero-module--testimonials-hero__clients-logo-wrapper--fU0Jo";
export var testimonialsHero__clientsLogo = "testimonials-hero-module--testimonials-hero__clients-logo--bxAmK";
export var testimonialsHero__counts = "testimonials-hero-module--testimonials-hero__counts--AqLBY";
export var testimonialsHero__countsTitle = "testimonials-hero-module--testimonials-hero__counts-title--5n1A7";
export var testimonialsHero__countsDesc = "testimonials-hero-module--testimonials-hero__counts-desc--i-uUv";
export var testimonialsHero__gradient = "testimonials-hero-module--testimonials-hero__gradient--6ybgK";
export var testimonialsHero__gradient_mobile = "testimonials-hero-module--testimonials-hero__gradient_mobile--aAyvL";
export var testimonialsHero__gradient_desktop = "testimonials-hero-module--testimonials-hero__gradient_desktop--O2XjT";
export var testimonialsHero__gradient_tablet = "testimonials-hero-module--testimonials-hero__gradient_tablet--bXrmG";